@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-date-range/dist/styles.css";
@import "~react-date-range/dist/theme/default.css";

body {
    min-width: 320px;
}

.text-decoration-none {
    text-decoration: none !important;
}

@import "loader";
@import "facet";
@import "custom-date-range";
@import "reddit-comment";

input[type="search"]::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
    /* Now your own custom styles */
    height: 1.25rem;
    width: 1.25rem;
    display: block;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgwLjAwMjYxMywgMCwgMCwgLTAuMDAyNjEzLCAwLjg1MzA4NSwgMTkuMTQ0ODY3KSIgZmlsbD0iIzZCNzI4MCIgc3Ryb2tlPSJub25lIj4KICAgIDxwYXRoIGQ9Ik0yMDkzIDUwMDkgYy0xMDggLTUzIC0xNTIgLTE3OCAtMTAyIC0yODkgMTUgLTM0IDE1OCAtMTg0IDYwNyAtNjMyIGw1ODcgLTU4OCAtNTkxIC01OTIgYy02NTEgLTY1MyAtNjMxIC02MjkgLTYyMiAtNzQyIDkgLTEwNCA5MCAtMTg1IDE5NCAtMTk0IDExMyAtOSA4OSAtMjkgNzQyIDYyMiBsNTkyIDU5MSA1OTMgLTU5MSBjNjUyIC02NTEgNjI4IC02MzEgNzQxIC02MjIgMTA0IDkgMTg1IDkwIDE5NCAxOTQgOSAxMTMgMjkgODkgLTYyMiA3NDIgbC01OTEgNTkyIDU5MSA1OTMgYzY1MSA2NTIgNjMxIDYyOCA2MjIgNzQxIC05IDEwNCAtOTAgMTg1IC0xOTQgMTk0IC0xMTMgOSAtODkgMjkgLTc0MiAtNjIyIGwtNTkyIC01OTEgLTU4OCA1ODcgYy00NDggNDQ5IC01OTggNTkyIC02MzIgNjA3IC01OSAyNyAtMTMzIDI3IC0xODcgMHoiIHN0eWxlPSJzdHJva2U6IHJnYigxMDcsIDExNCwgMTI4KTsgc3Ryb2tlLXdpZHRoOiAxNTMuMDgxcHg7Ii8+CiAgPC9nPgo8L3N2Zz4=);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;
    background-size: 1.25rem;
    cursor: pointer;
}
